import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import Image, { FluidObject } from 'gatsby-image';
import { uniq } from 'lodash';
import React, { useState } from 'react';
import sizes from 'style/sizes';
import {
  baseTransitionSecond,
  easeOut,
  easeOut02,
  grayscaleFilter,
  grayscaleFilterSimple,
  grayscaleFilterTop,
  mq,
} from 'style/variables';
import { ImgQuery } from 'types/graphql-types';
import { Caption } from '../caption';
import { InView } from 'react-intersection-observer';
import { useIsSp } from 'util/useIsSp';
import Gradient from 'assets/images/common/gradient.png';

type ImgProps = {
  filename: string | string[];
  maskImage?: string[]; // 0=sp, 1=pc
  alt: string;
  caption?: string;
  spFullSize?: boolean;
  fluid?: FluidObject | FluidObject[];
  isContain?: boolean;
  isHovered?: boolean;
  noTriggerInView?: boolean;
};

const StaticImg = styled('img')<ImgProps>`
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  vertical-align: bottom;

  ${mq.onlysp} {
    ${props =>
      props.spFullSize &&
      css`
        margin: 0 -${sizes.margin[32]};
        width: 100vw;
      `}
  }
`;

const ImgItem = styled(Image)<ImgProps>`
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  vertical-align: bottom;

  ${props =>
    props.fluid &&
    Array.isArray(props.fluid) &&
    props.fluid.length > 1 &&
    css`
      > div:first-of-type {
        ${mq.onlysp} {
          padding-bottom: ${100 / props.fluid[0].aspectRatio}% !important};
        }
      }
    `}
  ${mq.onlysp} {
    ${props =>
      props.spFullSize &&
      css`
        margin: 0 -${sizes.margin[32]};
        width: 100vw;
      `}
  }

  // gatsbyのimgコンポーネントを読み解くことができなかったため、強制的にimportantを使用して上書きする
  picture > img {
    ${props =>
      props.isContain &&
      css`
        object-fit: contain !important;
      `}
  }
`;

const ImageCaption = styled(Caption)`
  margin-top: ${sizes.margin[32]};
  ${mq.onlysp} {
    margin-top: ${sizes.margin[17]};
  }
`;

const Wrapper = styled.div<{ isHovered?: boolean }>`
  width: 100%;
  height: 100%;

  ${({ isHovered }) =>
    isHovered &&
    css`
      filter: none;
    `}
`;

const Img: React.FC<ImgProps> = props => {
  const [isHovered, setIsHovered] = useState(false);
  const isSp = useIsSp(); // 強制trigger in view
  const isTriggerInView = isSp || !props.noTriggerInView;
  // const isSp = useIsSp();

  const hasMaskImage = props.maskImage !== undefined && props.maskImage !== null && props.maskImage.length > 0;

  const content = (
    <Wrapper
      isHovered={isHovered}
      onMouseEnter={() => !isTriggerInView && setIsHovered(true)}
      onMouseLeave={() => !isTriggerInView && setIsHovered(false)}
    >
      <ImgBaseExtends {...props} hasMaskImage={hasMaskImage} isHovered={isHovered} />
    </Wrapper>
  );

  if (isTriggerInView) {
    return (
      <InView onChange={inView => setIsHovered(inView)} threshold={0.5} delay={200}>
        {({ ref }) => <div ref={ref}>{content}</div>}
      </InView>
    );
  }

  return content;
};

const ImgBase: React.FC<ImgProps> = props => {
  const isSP = useIsSp();
  const data: ImgQuery = useStaticQuery(
    graphql`
      query Img {
        images: allFile(filter: { extension: { in: ["jpeg", "jpg", "svg", "gif", "png"] } }) {
          edges {
            node {
              relativePath
              name
              url
              publicURL
              childImageSharp {
                fluid(maxWidth: 1440, quality: 90) {
                  aspectRatio
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  );
  let image;
  if (
    typeof props.filename === 'string' &&
    (props.filename.includes('http') || props.filename.includes('.svg') || props.filename.includes('.gif'))
  ) {
    // 外部ファイルの場合はそのまま取得
    if (props.filename.includes('http')) {
      return (
        <>
          <StaticImg src={props.filename} className={'a-image'} {...props} />
          {props.caption && <ImageCaption>{props.caption}</ImageCaption>}
        </>
      );
    }
    image = data.images.edges.filter(n => {
      return n.node.relativePath.includes(props.filename as string) || n.node.url?.includes(props.filename as string);
    });
    if (!image) {
      return null;
    }
    // publickURLを取得
    const src = image[0]?.node?.publicURL;
    if (!src) return null;
    return (
      <>
        <StaticImg src={src} className={'a-image'} {...props} />
        {props.caption && <ImageCaption>{props.caption}</ImageCaption>}
      </>
    );
  }
  // gatsby image用
  if (Array.isArray(props.filename)) {
    image = uniq(props.filename).map(file =>
      data.images.edges.find(n => {
        return n.node.relativePath.includes(file) || n.node.url?.includes(file);
      })
    );
  } else {
    image = data.images.edges.filter(n => {
      return n.node.relativePath.includes(props.filename as string) || n.node.url?.includes(props.filename as string);
    });
  }
  // gatsby image mask
  let mask;
  if (Array.isArray(props.maskImage)) {
    mask = uniq(props.maskImage).map(file =>
      data.images.edges.find(n => {
        return n.node.relativePath.includes(file) || n.node.url?.includes(file);
      })
    );
    if (!mask[1]) {
      mask[1] = mask[0];
    }
  }
  if (!image || image.length === 0 || image.some(el => el === undefined)) {
    return null;
  }
  const fluid = image.map((n, index: number) => {
    if (!n?.node.childImageSharp?.fluid) return [];
    if (index > 0 && n?.node?.childImageSharp?.fluid)
      return { ...n?.node?.childImageSharp?.fluid, media: `(min-width: 768px)` };
    return { ...n?.node?.childImageSharp?.fluid };
  });
  if (fluid?.some(el => el === undefined)) {
    return <></>;
  }
  if (!fluid || fluid.length === 0 || fluid.some(el => el === undefined)) {
    return <></>;
  }

  return (
    <>
      <ImgItem
        fluid={fluid}
        className={'a-image'}
        placeholderStyle={{ display: 'none' }}
        fadeIn
        durationFadeIn={baseTransitionSecond}
        {...props}
      />
      {mask && (
        <>
          {/* // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore */}
          <DotLikeCover isHovered={props.isHovered} />
          <GrayCover isHovered={props.isHovered} mask={isSP ? mask[0]?.node.publicURL : mask[1]?.node.publicURL} />
        </>
      )}
      {props.caption && <ImageCaption>{props.caption}</ImageCaption>}
    </>
  );
};

// マスク付きカバー。周辺の色を制御
const GrayCover = styled('div')<{ isHovered?: boolean; mask?: string }>`
  position: absolute !important;
  inset: 0;
  backdrop-filter: ${grayscaleFilter};
  @keyframes hover-reverse {
    0% {
      backdrop-filter: none;
    }
    to {
      backdrop-filter: ${grayscaleFilter};
    }
  }
  animation: hover-reverse ${baseTransitionSecond * 6}s ease-in-out forwards;

  ${({ mask }) =>
    !!mask &&
    css`
      mask-image: url(${mask});
      mask-repeat: no-repeat;
      mask-position: center center;
      mask-size: cover;
    `}

  ${({ isHovered }) =>
    isHovered &&
    css`
      @keyframes hover {
        0% {
          backdrop-filter: ${grayscaleFilter};
        }
        10% {
          backdrop-filter: ${grayscaleFilterTop};
        }
        100% {
          backdrop-filter: none;
        }
      }
      animation: hover ${baseTransitionSecond * 6}s ease-in-out forwards;
      animation-delay: ${baseTransitionSecond * 4.5}s;
      /* opacity: 0; */
      /* transition-delay: ${baseTransitionSecond * 3}s; */
    `};
`;

const DotLikeCover = styled('div')<{ isHovered?: boolean }>`
  position: absolute !important;
  inset: 0;
  background-image: url(${Gradient});
  background-size: 100% 100%;
  mix-blend-mode: color-burn;
  background-position: 0 0;

  @keyframes hover3-reverse {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: hover3-reverse ${baseTransitionSecond * 4}s linear forwards;

  ${({ isHovered }) =>
    isHovered &&
    css`
      @keyframes hover3 {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
      animation: hover3 ${baseTransitionSecond * 4}s linear forwards;
      animation-delay: ${baseTransitionSecond * 1}s;
      /* background-position: 10px 0px; */
    `};
`;

// hover演出よベース。まず色が変わる箇所を制御
const ImgBaseExtends = styled(ImgBase)<{ hasMaskImage?: boolean; isHovered?: boolean }>`
  filter: ${grayscaleFilterSimple};
  @keyframes hover2-reverse {
    0% {
      filter: none;
    }
    100% {
      filter: ${grayscaleFilterSimple};
    }
  }
  animation: hover2-reverse ${baseTransitionSecond * 4}s ${easeOut} forwards;


  ${({ isHovered }) =>
    isHovered &&
    css`
      @keyframes hover2 {
        0% {
          filter: ${grayscaleFilterSimple};
        }
        100% {
          filter: none;
        }
      }
      animation: hover2 ${baseTransitionSecond * 4}s ${easeOut} forwards;
    `};
};

  ${({ hasMaskImage, isHovered }) =>
    hasMaskImage &&
    isHovered &&
    css`
      animation-delay: ${baseTransitionSecond * 1}s;
    `};
`;

export default Img;
